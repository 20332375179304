import { connect, WithStore, MapStateToProps, MapDispatchToProps } from '../../redux/services/Imports';
import { baseURLModx } from '../../redux/services/APIRequestsModx';
import { Props } from './interfaces';
import './ServiceList.scss';
import './ServiceList-media.scss';
import { useEffect, useRef } from 'react';
import { useGSAP } from '@gsap/react';
import { goAnimationServices } from './functions';
import { Sticky } from '../../components/Sticky/Sticky';

const ServiceList = ({ title, list, height, widthWindow }: Props) => {
  const container: any = useRef();
  useGSAP(
    () => {
      goAnimationServices(widthWindow > 720 ? '85px' : '60px');
    },
    { scope: container }
  );

  // const handleScroll = (blocks: any) => (e: any) => {
  //   console.log(e);

  //   blocks.forEach((block: HTMLElement) => {
  //     block.style.height = '50px';
  //   });
  // };

  // useEffect(() => {
  //   let element = document.querySelector('#target');
  //   let Visible = function (target) {
  //     // Все позиции элемента
  //     let targetPosition = {
  //         top: window.pageYOffset + target.getBoundingClientRect().top,
  //         left: window.pageXOffset + target.getBoundingClientRect().left,
  //         right: window.pageXOffset + target.getBoundingClientRect().right,
  //         bottom: window.pageYOffset + target.getBoundingClientRect().bottom
  //       },
  //       // Получаем позиции окна
  //       windowPosition = {
  //         top: window.pageYOffset,
  //         left: window.pageXOffset,
  //         right: window.pageXOffset + document.documentElement.clientWidth,
  //         bottom: window.pageYOffset + document.documentElement.clientHeight
  //       };
  //     if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
  //       targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
  //       targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
  //       targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
  //       // Если элемент полностью видно, то запускаем следующий код
  //       console.clear();
  //       console.log('Вы видите элемент :)');
  //     } else {
  //       // Если элемент не видно, то запускаем этот код
  //       console.clear();
  //     };
  //   };
  //   // Запускаем функцию при прокрутке страницы
  //   window.addEventListener('scroll', function() {
  //     Visible (element);
  //   });
  //   // А также запустим функцию сразу. А то вдруг, элемент изначально видно
  //   Visible (element);

  //   let blocks: NodeListOf<Element>;

  //   setTimeout(() => {
  //     blocks = document.querySelectorAll('.service-list__item');

  //     if (blocks) window.addEventListener('scroll', handleScroll(blocks));
  //   }, 500);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div className="service-list" ref={container}>
      <h2 className="service-list__title">{title}</h2>

      <div className="service-list__container-sticky">
        <Sticky
          height={height}
          containerWidth={widthWindow > 720 ? 'calc(100% - 80px)' : 'calc(100% - 32px)'}
          top="20px"
        >
          {list.map(({ title, text, image }, i) => (
            <div className="service-list__item" key={i}>
              <div className="service-list__item-container">
                <h3 className="service-list__item-title">{title}</h3>
                <p className="body1 service-list__item-text">{text}</p>
                {image ? (
                  <img src={`${baseURLModx}/${image}`} className="service-list__item-image" alt="" />
                ) : null}
              </div>
            </div>
          ))}
        </Sticky>
      </div>
    </div>
  );
};

export default WithStore()(connect(MapStateToProps, MapDispatchToProps)(ServiceList));
