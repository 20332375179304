import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Props } from './interfaces';
import { SvgArrow } from '../../../services/Constants';

const LinkMenu = ({ menu, className, children, onClickLink }: Props) => {
  let location = useLocation().pathname;

  const { link, text, intoLink, iconLink } = menu;

  const onEffects = {
    onMouseEnter: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const isActive = (e.target as HTMLElement)
        .closest('.menu-top__item-menu')
        ?.classList.contains('menu-top__item-menu_active');

      if (!isActive) {
        (e.target as HTMLElement).classList.add('menu-top__link_start-animation');
        (e.target as HTMLElement).classList.remove('menu-top__link_end-animation');
      }
    },
    onMouseLeave: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      hideActiveItemMenu(e.target as HTMLElement);
    },
    onFocus: (e: React.FocusEvent<HTMLAnchorElement, Element>) => {
      setTimeout(() => {
        document.querySelectorAll('.menu-top__link_start-animation').forEach((x) => {
          if (e.target !== x) hideActiveItemMenu(x as HTMLElement);
        });
      }, 200);
    },
  };

  const hideActiveItemMenu = (element: HTMLElement) => {
    const isActive = element
      .closest('.menu-top__item-menu')
      ?.classList.contains('menu-top__item-menu_active');

    if (!isActive) {
      element.classList.remove('menu-top__link_start-animation');
      element.classList.add('menu-top__link_end-animation');
    }
  };

  return (
    <div
      className={`menu-top__item-menu${link === location ? ' menu-top__item-menu_active' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {link.length ? (
        intoLink ? (
          <>
            <a
              href={link}
              target="_blank"
              className={`buttonM menu-top__link`}
              onClick={onClickLink ? onClickLink : () => {}}
              dangerouslySetInnerHTML={{ __html: text }}
              {...onEffects}
            ></a>
            {iconLink && SvgArrow}
          </>
        ) : (
          <>
            <Link
              to={link}
              className={`buttonM menu-top__link`}
              onClick={onClickLink ? onClickLink : () => {}}
              dangerouslySetInnerHTML={{ __html: text }}
              {...onEffects}
            ></Link>
            {iconLink && SvgArrow}
          </>
        )
      ) : (
        <p className="buttonM menu-top__link" dangerouslySetInnerHTML={{ __html: text }}></p>
      )}

      {children}
    </div>
  );
};

export default LinkMenu;
