import { ISpeak } from '../../blocks/WeAreSpeaking/interfaces';
import { IModxMainPage, IModxServiceBlock } from '../../redux/services/InterfacesModx';

export const ListSpeaking: ISpeak[] = [
  {
    text: 'Тёплые слова и желание жить: встречаем День рекламиста <br/>на Sostav',
    link: '',
    textLink: 'Sostav',
  },
  {
    text: 'Тёплые слова и желание жить: встречаем День рекламиста <br/>на AdIndex',
    link: '',
    textLink: 'AdIndex',
  },
  {
    text: `Тёплые слова и желание жить: встречаем День рекламиста <br/>на VC`,
    link: '',
    textLink: 'VC',
  },
];

export const STUB_MAIN: IModxMainPage = {
  object: {
    id: '',
    seo: {
      title: '',
      description: '',
    },
    main_screen: {
      title: '',
      main_button_text: '',
      show_main_button: false,
      secondary_button_text: '',
      show_secondary_button: false,
      tags: '',
    },
    mtg_block: {
      title: '',
      photos: [],
    },
    about_block: {
      testimonials: [],
    },
    talk_block: {
      title: '',
      photos: [],
      links: [],
    },
  },
};

export const STUB_SERVICES_MAIN: IModxServiceBlock = {
  title: '',
  services: [],
};
