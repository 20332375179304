import { catchError, of, forkJoin } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { APIModx } from './APIRequestsModx';
import {
  IReduxAgencyPage,
  IReduxCasePage,
  IReduxContactsPage,
  IReduxMainPage,
  IReduxProjectsPage,
  IReduxSendMail,
  IReduxServicesPage,
} from './InterfacesRedux';
import {
  IModxAgencyPage,
  IModxCasePage,
  IModxContactsPage,
  IModxMainPage,
  IModxProjectsPage,
  IModxServicesPage,
} from './InterfacesModx';
import { GetServerTextError } from './GlobalFunctions';

class ServiceRedux {
  /* --- Main ------------------------------------------------------------- */

  sendEmail = ({ data, success, setServerText }: IReduxSendMail) => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('message', data.description);
    formData.append('email', 'test@test.ru');
    formData.append('company', '');

    APIModx.sendEmail({ data: formData })
      .pipe(
        tap(() => {
          success();
        }),
        catchError((err: any) => {
          setServerText(GetServerTextError(err));
          return of(err);
        })
      )
      .subscribe();
  };

  getMainPage = ({ en, alertLoaded, success }: IReduxMainPage) => {
    APIModx.getMainPage({ en })
      .pipe(
        tap((data: IModxMainPage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };

  getAgencyPage = ({ en, alertLoaded, success }: IReduxAgencyPage) => {
    APIModx.getAgencyPage({ en })
      .pipe(
        tap((data: IModxAgencyPage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };

  getServicesPage = ({ en, alertLoaded, success }: IReduxServicesPage) => {
    APIModx.getServicesPage({ en })
      .pipe(
        tap((data: IModxServicesPage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };

  getProjectsPage = ({ en, alertLoaded, success }: IReduxProjectsPage) => {
    APIModx.getProjectsPage({ en })
      .pipe(
        tap((data: IModxProjectsPage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };

  getCasePage = ({ id, en, alertLoaded, success }: IReduxCasePage) => {
    APIModx.getCasePage({ id, en })
      .pipe(
        tap((data: IModxCasePage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };

  getContactsPage = ({ en, alertLoaded, success }: IReduxContactsPage) => {
    APIModx.getContacts({ en })
      .pipe(
        tap((data: IModxContactsPage) => success(data)),
        catchError(PrintAlertError(alertLoaded))
      )
      .subscribe();
  };
}

export const ServiceData = new ServiceRedux();

const ErrorArticle =
  ({ alertLoaded, state404Loaded }: { alertLoaded: any; state404Loaded?: any }) =>
  (err: any) => {
    if (err.response) {
      switch (err.response.status) {
        case 400:
        case 401:
          alertLoaded(err.response.data.message);
          break;
        case 404:
          alertLoaded('');
          state404Loaded(true);
          break;
        default:
          alertLoaded(err.response.data.message);
      }
    } else {
      console.log(err);
      alertLoaded([err]);
    }

    return of(err);
  };

const PrintAlertError = (alertLoaded: any) => (err: any) => {
  if (err.response) {
    switch (err.response.status) {
      case 400:
      case 401:
        alertLoaded(err.response.data.message);
        break;
      case 404:
        alertLoaded('');
        break;
      default:
        alertLoaded(err.response.data.message);
    }
  } else {
    console.log(err);
    alertLoaded([err]);
  }

  return of(err);
};
